<template>
<b-container>
  <div class="my-5 pt-5">
    <h4 class="gray">
      ลงทะเบียน
    </h4>
    <divider />
    <b-container class="mt-5 pt-5">
      <b-form  v-on:submit.prevent="onRegister">
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto"> 
          <label> E-mail</label>
          <b-form-input type="email" v-model="email" required/>
          <span class="red" v-if="validation.email">{{ validation.email }}</span>
        </b-form-group>
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto"> 
          <label> ชื่อ-นามสกุล</label>
          <b-form-input type="text" v-model="fullname" required/>
          <span class="red" v-if="validation.fullname">{{ validation.fullname }}</span>
        </b-form-group>
         <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto"> 
          <label> เบอร์โทรศัพท์</label>
          <b-form-input type="text" v-model="tel" required/>
          <span class="red" v-if="validation.tel">{{ validation.tel }}</span>
        </b-form-group>
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto mt-2"> 
          <label> รหัสผ่าน</label>
          <b-form-input type="password" v-model="password" required/>
          <span class="red" v-if="validation.password">{{ validation.password }}</span>
        </b-form-group>
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto mt-2"> 
          <label> ยืนยันรหัสผ่าน</label>
          <b-form-input type="password" v-model="confirmPassword" required/>
          <span class="red" v-if="password !== confirmPassword">กรุณาใส่รหัสผ่านให้ตรงกัน</span>
        </b-form-group>
        <b-form-group class="col-lg-6 col-md-8 col-sm-12 m-auto pt-4"> 
          <b-button type="submit" class="btn-primary btn-block" :disabled="isDisabled">
            ลงทะเบียน
          </b-button>
        </b-form-group>
      </b-form>
    </b-container>
  </div>
</b-container>
</template>
<script>
import { createSeeker, authLoginUser } from "@/services/auth.service"
import Divider from "@/components/Divider";
export default {
  components: {
    Divider
  },
  watch: {
    confirmPassword() {
      if(this.password !== this.confirmPassword) {
        this.isDisabled = true
      }else {
        this.isDisabled = false
      }
    },
    email() {
      this.validation.email = ""
    },
    password() {
      this.validation.password = ""
      this.confirmPassword = ""
    },
    tel() {
      this.validation.tel = ""
    },
    fullname() {
      this.validation.fullname = ""
    }
  },
  methods: {
    async onRegister() {
      const response = await createSeeker({
        email: this.email,
        password: this.password,
        phone: this.tel,
        fullname: this.fullname
      })
      console.log(response)
      if(response.validation){
        const { validation } = response
        if(validation.email) this.validation.email = validation.email
        if(validation.password) this.validation.password = validation.password
        if(validation.phone) this.validation.tel = validation.phone
        if(validation.fullname) this.validation.fullname = validation.fullname
        return;
      }else {
        const response = await authLoginUser({
          username: this.email,
          password: this.password,
        })
        if(response.error)
          this.$toast.error(response.message)
        
        this.$toast.success('Registration is successful')
        window.location.href = "/";
      }
    }
  },
  data() {
    return {
      email: "",
      password: "",
      tel: "",
      fullname: "",
      confirmPassword: "",
      isDisabled: true,
      validation: {
        email: "",
        password: "",
        tel: "",
        fullname: "",
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  label {
    font-size: 20px;
    color: $primary-hard-color;
    margin: 0;
  }
  .btn {
    padding: 4px;
  }
  .red {
    color: red;
  }
</style>